import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
const ClientSideOnlyLazy = React.lazy(() =>
  import("../components/Home/properties")
)


//Components
import Main from '../components/Home/main'
import Developments from '../components/Home/developments'
import Properties from '../components/Home/properties'
import About from '../components/Home/about'
import Novelties from '../components/Home/novelties'
import Investors from '../components/investors'

const IndexPage = () => {
  const isSSR = typeof window === "undefined"
      
  return(
  <Layout>
    <Seo title="Venta y Alquiler de Propiedades" />
    {/* <Main /> */}
    <Developments />
    {!isSSR && (
      <React.Suspense fallback={<div />}>
        <ClientSideOnlyLazy />
      </React.Suspense>
    )}
    <About />
    <Investors />
    <Novelties />
  </Layout>
)}

export default IndexPage
