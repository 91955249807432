import React, {useState, useRef, useEffect} from 'react'
import Card from '../property-card'
import { graphql, useStaticQuery } from 'gatsby';
import OwlCarousel from 'react-owl-carousel3';
import { getOperations } from '../../helpers/helper.properties'
import { makeUrlVenta } from '../../helpers/helper.rendering'

//Redux
import { connect } from 'react-redux'

//Dispatch
import {getFeaturedPropertiesAction} from '../../redux/propertiesDucks'

const options = {
    loop:true,
    smartSpeed:1000,
    stagePadding:10,
    center:true,
    margin:10,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1,
        },
        700: {
            items: 2,
        },
        1000: {
            items: 2,
        }
    },
}

const Properties = ({properties,api_key,dispatch,allLocations}) => {

    const slider = useRef()
    const { realEstate } = useStaticQuery(graphql`
        query {
            realEstate {
                sections {
                    home {
                        properties{
                            title
                        }
                    }
                }
            } 
        }`)

    useEffect(() => {
        if(api_key && properties.length === 0){
            dispatch(getFeaturedPropertiesAction())
        }
    }, [api_key])

    const [filter, setFilter] = useState('Venta')
    const [buttons, setButtos] = useState([
        {
            name:"VENTAS",
            data:'Venta',
            value:0
        },
        {
            name:"ALQUILER",
            data:'Alquiler',
            value:1
        },
        {
            name:"ALQ. TEMPORARIO",
            data:'Alquiler temporario',
            value:2
        }

    ])
    const checkOperations = (properties,value) => {
        let count = 0;
        properties.map(property => {
            let operation = getOperations(property)
            if(operation.find(element => element === value)){
                count++;
            }
        }
        )
        return count
    }
    const filterByLocation = "Capital Federal";

    return properties?.data?.objects?.length > 0 ? (
        <section id="featured-props">
            <div class="container-fluid filter-btn-wrapper">
                <div className="d-flex flex-wrap justify-content-center">
                    { buttons.map((button,index) => (
                        <button className={'btn button-filter mb-2 mb-lg-0 ' 
                            + (checkOperations(properties.data.objects, button.data) === 0 ? ' d-none ' : '')
                            + (filter === button.data ? ' active ' : '')} 
                            onClick={() => setFilter(button.data)} key={index}>
                                {button.name}
                        </button> ))}
                </div>
            </div>
            <div className="props d-flex align-items-center justify-content-center my-4">
                <OwlCarousel ref={slider}
                    {...options}
                    className="overflow-hidden">
                    {/* Filter by: location 'Capital Federal' & operation type by filter */}
                    {properties.data.objects
                        .filter(property => property.location.short_location.split('|').find( loc => loc.includes(filterByLocation)))
                        .filter(property => property.operations.find(operation => operation.operation_type === filter))
                        .map((prop,index) => (
                            <Card key={index} basic={true} property={prop} showByFilter={filter} />
                    ))}
                </OwlCarousel>
                {/* <div className="arrows">
                    <div className="arrow" onClick={() => slider.current.prev()}> {'<'} </div>
                    <div className="arrow" onClick={() => slider.current.next()}> {'>'} </div>
                </div> */}
                <div className="arrows-owl">
                    <div className="d-lg-none arrow" onClick={() => slider.current.prev()}> <i className="isArrowFeatured" ></i> <i className="isArrowFeatured" ></i></div>
                    <div className="d-lg-none arrow reverse" onClick={() => slider.current.next()}> <i className="isArrowFeatured" ></i> <i className="isArrowFeatured" ></i> </div>

                    <i className='d-none d-lg-block icon-arrow-left isArrowFeatured' onClick={() => slider.current.prev()}   ></i>
                    <i className='d-none d-lg-block icon-arrow isArrowFeatured' onClick={() => slider.current.next()}  ></i>
                </div>
            </div>
            <div className="d-flex justify-content-center wrapper-cta">
                <a href={makeUrlVenta(allLocations, filterByLocation)} class="btn btn-blue">VER TODAS LAS OPORTUNIDADES</a>
            </div>
        </section>
    ):''
}

export default connect(state => ({
    properties: state.properties.featured,
    api_key:state.settings.keys.tokko,
    allLocations:state.properties.allLocationFilters
}),null)(Properties);