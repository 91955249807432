import { Link } from "gatsby"
import React,{ useEffect } from "react"
import NoveltyCard from "../novelty-card";
import {getNoveltiesAction} from "../../redux/noveltiesDucks"
import {getThree} from '../../helpers/helper.novelty'
import ContentLoader from "react-content-loader"
import { connect } from "react-redux";
import { graphql, useStaticQuery } from 'gatsby';

const Novelties = ({loading_novelties,novelties,tags,dispatch}) => {
    
    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              name
              env { 
                APISERVER
                TENANTID
                CLIENTID
              }
          }
    }`)

    const {env} = realEstate

    useEffect(() => {
        dispatch(getNoveltiesAction(env,true))
    },[])

    console.log(novelties)

    return novelties.length > 0 && (
        <section class="novelties py-5" >
            <div class="opacity"></div>
            <div class="container-fluid py-5">
                <div class="row">
                    <div class="col-12 col-lg-2">
                        <p class="section-name">NOVEDADES</p>
                    </div>
                    <div class="col-12 col-lg-10">
                        <h2>MANTENETE AL TANTO DE LAS ÚLTIMAS TENDENCIAS.</h2>
                    </div>
                    
                    <div class="col-12 col-lg-10 offset-0 offset-lg-2 pt-5 mt-5">
                        <div class="row py-lg-5">     
                                 {
                                    loading_novelties ?
                                        [1,2,3].map(count => (
                                            <div className="mb-4 mb-lg-0 col-12 col-lg-4">
                                                <div className='me-3 d-inline'>
                                                    <ContentLoader 
                                                        speed={2}
                                                        width={"100%"}
                                                        height={"46vh"}
                                                        viewBox="0 0 100% 46vh"
                                                        backgroundColor="#f3f3f3ee"
                                                        foregroundColor="#ecebebee"
                                                    >
                                                        <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" /> 
                                                    </ContentLoader>
                                                </div>
                                            </div>
                                        ))
                                        :
                                    getThree(novelties).map((novelty,index) => (
                                            <div className="mb-4 mb-lg-0 col-12 col-lg-4">
                                                <NoveltyCard isHome={true} novelty={novelty} hidenTags={true} />
                                            </div>
                                    ))
                                 }
                        </div>
                    </div>
                    <div class="col-12 col-lg-10 mt-5 offset-lg-2 text-start">
                        <Link to="novedades" class="btn btn-red">Ver todas las novedades</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default connect(state => ({
    loading_novelties: state.novelties.loading_novelties,
    novelties: state.novelties.novelties,
    tags:state.novelties.tags
  }),null)(Novelties);