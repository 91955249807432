import React from 'react'

import { Link} from 'gatsby'

//Helpers
import {getLocation, getName, getFakeAddress, getStatus, is_Sold} from '../helpers/helper.developments'
import {getCover, getQuality, getPriceForMarker} from '../helpers/helper.rendering'
import {getMinAndMaxPrice} from '../helpers/helper.unities'
import { graphql, useStaticQuery } from 'gatsby';
import { getEnvironment, getPrices, getSurface, makeLink } from '../helpers/helper.properties';
import { connect } from 'react-redux'
import { changeSelectedAction } from '../redux/propertiesDucks'

const developmentGroupCard = (props) => {

    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              name
          }
    }`)
    const {development,developments,dispatch,tag,max} = props
    const inMap = props?.inMap
    const unities = props?.unities
    const forHome = props?.forHome;

    const getDevelopmentByTag = () => {
        const developmentsSearch = []
        for (const dev of developments) {
            if(dev.custom_tags.find(element => element.name.toLowerCase().replaceAll(" ","-") === tag?.name.toLowerCase().replaceAll(" ","-"))){
                developmentsSearch.push(dev)
            }
        }
        return developmentsSearch.find(element => element.custom_tags.find(atag => atag.name.toLowerCase() === 'portada'))
    }

    // console.log(developments)

    return(
        <div class={`card dev-card isGroup ${forHome ? 'home-dev-cards' : 'default-dev-cards'}`}>
            {getDevelopmentByTag(developments,tag) 
                && <img src={getCover(getDevelopmentByTag(developments,tag)?.photos).original} alt={tag.name} />}
            <div className="container-text text-start d-flex flex-column">
                <h2 className="card-title mb-5">{getDevelopmentByTag(developments,tag)?.name}</h2>
                <h5 className="card-direction mt-2">
                    {getDevelopmentByTag(developments,tag) && getDevelopmentByTag(developments,tag)?.fake_address} <br />
                    {getDevelopmentByTag(developments,tag)?.location?.short_location.split('|')[0]} <br />
                    {getDevelopmentByTag(developments,tag)?.location?.name}
                </h5>
                <a href={"/emprendimientos/" + tag.name.replaceAll(" ","-").toLowerCase()} 
                   class="btn btn-outline mt-auto">DESCUBRILO</a>
            </div>
        </div> 
    )
}

export default connect(state => ({
    settings: state.settings,
}),null)(developmentGroupCard);