// import { navigate } from '@reach/router'
import React from 'react'
import { connect } from 'react-redux'
import {getTotalListFilters, clearPropertiesAction, setFilterHome, REDUX_UPDATE_FILTERS, getFiltersByOperation, getLocationsLiteByTypeAction} from '../redux/propertiesDucks'
import { useRef } from "react";
import { useState, useEffect } from "react";
import Searcher from "./autocomplete";
import SearcherPro from "./autocompletePro";
// import { useNavigate } from '@reach/router'
import { graphql, navigate, useStaticQuery } from "gatsby"
import toast, { Toaster } from 'react-hot-toast';
import $ from 'jquery'

const Search = ({totalListFilters,listLocationLite, searchHome,dispatch,listFiltersByOperation}) => {

    const { realEstate } = useStaticQuery(graphql`
    query {
        realEstate {
            template_version
            proFeatures{
                locationsAdv
            }
        }
    }`)
    const {template_version,proFeatures} = realEstate
    const MediaPro = template_version === 'PRO' ? true : false;
    const {locationsAdv} = proFeatures

    // const navigate = useNavigate();
    const refOperation = useRef();
    const refType = useRef();
    const [locations, setLocation] = useState([]);

    const [errorInput,setErrorInput] = useState(false)
  
    const [router, setRouter] = useState({
      location: "",
      type: "",
      operation: "venta",
    });
  
    useEffect(() => {
        if (listLocationLite.data) {
            setLocation([])
            for (let index = 0; index < listLocationLite.data.objects.locations.length; index++) {
            // if(locations.length <= 0){
                setLocation((locations) => [
                ...locations,
                listLocationLite.data.objects.locations[index].location_name,
                ]);
            }
            // }
        }
    }, [listLocationLite]);

    const updateRoute = (value) => {
      setRouter((router) => ({
        ...router,
        [value.current.name]: value.current.value,
      }));
    };

    useEffect(() => {
        switch(router.operation.toLowerCase()){
            case "venta":
                updateOperation({id:1,name:"Venta"})
                break;
            case 'alquiler':
                updateOperation({id:2,name:"Alquiler"})
                break;
            case 'alquiler temporario':
                updateOperation({id:3,name:"Alquiler Temporario"})
                break;
            default:
        }
    },[router.operation])

    const goRoute = (event) => {
        event.preventDefault();
        if(true){
            dispatch(clearPropertiesAction());
            let routerLink =( searchHome.operation.toLowerCase().replace(/ /g, "-"));
            routerLink += (searchHome.type !== ''
                      ? '/' + searchHome?.type?.toLowerCase().replace(/ /g, "-").normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                      : '' );
            routerLink += (
                  (
                    searchHome.location.length > 0 
                    ?   '/' +  searchHome.location.map((element,index) => (element.name.toLowerCase().replace(/ /g, "-").normalize("NFD").replace(/[\u0300-\u036f]/g, "")))
                    :   ''
                  )
            );
            navigate(routerLink);
        }
        // if(totalListFilters.data.objects.locations.find(element => element.location_name.toLowerCase() === searchHome.location.toLowerCase()) || searchHome.location === '' || searchHome.location === ' '){
        //     // console.log(router.location)
        //     dispatch(clearPropertiesAction());
        //     let routerLink =(
        //           searchHome.operation
        //           .toLowerCase()
        //           .replace(/ /g, "-") +
        //           (searchHome.type ? "/" : '')
        //           + searchHome.type
        //           .toLowerCase()
        //           .normalize("NFD")
        //           .replace(/[\u0300-\u036f]/g, "") 
        //           +
        //           (searchHome.location !== '' ? 
        //             '/' + 
        //             searchHome.location
        //             .toLowerCase()
        //             .replace(/ /g, "-")
        //             .normalize("NFD")
        //             .replace(/[\u0300-\u036f]/g, "")
        //           :'')
        //     );
        //     navigate(routerLink);
        //     console.log(routerLink)
        // }
        // else{
        //     setErrorInput(true);
        //     toast.error("No se encontraron resultados para la ubicación ingresada.",4000) 
        // }
    };

    const updateOperation = (value) => {
        dispatch(setFilterHome({
            ...searchHome,
            operation: value.name,
            type:'',
        }));
    }

    const updateTypes = (value) => {
        dispatch(setFilterHome({
            ...searchHome,
            type: value.current.value,
            location:[]
        }));
    }

    useEffect(() => {
        dispatch(setFilterHome({...searchHome,type:'',location:[]}))
        dispatch(getFiltersByOperation(searchHome.operation))
        dispatch(getLocationsLiteByTypeAction(searchHome.operation,searchHome.type))
        $("#type").val("all");
    },[searchHome.operation])

    useEffect(() => {
        dispatch(setFilterHome({...searchHome,location:[]}))
        dispatch(getLocationsLiteByTypeAction(searchHome.operation,searchHome.type))
    },[searchHome.type])

    useEffect(() => {
        dispatch(REDUX_UPDATE_FILTERS({
            location: [],
            type: [],
            operation: "",
            environments:'',
            bedrooms:'',
            age:'',
            producer_id:'',
            price:{
              type:'',
              priceMin:'',
              priceMax:'',
              is_off:false,
            },
            surface:{
              type:'',
              surfaceMin:"",
              surfaceMax:"",
            },
            environments_types: [],
            generals: [],
            services: [],
        specials: [],}))
        dispatch(setFilterHome({operation:'venta', 
        location:[],
        type:""}))
    },[])


    return totalListFilters ? (
        <div class="search-home d-flex align-items-center py-3 py-lg-4">
            <div class="container-fluid">
                <form onSubmit={goRoute} class="row no-gutters align-items-stretch px-xl-5 search-form mb-0">
                    <div class="col-12 my-2 col-lg-3 px-2 pr-lg-3">
                        <div class="btn-group w-100" role="group" aria-label="Basic example">
                            {totalListFilters.data && 
                            <>
                               {totalListFilters.data && totalListFilters.data.objects.operation_types.find(element => element.operation_type === 1) ? <button onClick={() => updateRoute({current:{value:"venta",name:"operation"}})}type="button" class={"btn btn-primary " + (router?.operation?.toLowerCase() === 'venta' ? 'active' : '')}>Venta</button> : '' }
                               {totalListFilters.data && totalListFilters.data.objects.operation_types.find(element => element.operation_type === 2) ? <button onClick={() => updateRoute({current:{value:"alquiler",name:"operation"}})}type="button" class={"btn btn-primary " + (router?.operation?.toLowerCase() === 'alquiler' ? 'active' : '')}>Alquiler</button> : '' }
                               {totalListFilters.data && totalListFilters.data.objects.operation_types.find(element => element.operation_type === 3) ? <button onClick={() => updateRoute({current:{value:"alquiler temporario",name:"operation"}})}type="button" class={"btn btn-primary " + (router?.operation?.toLowerCase() === 'alquiler temporario' ? 'active' : '')}>Temporario</button> : ''}
                            </>
                            }
                        </div>
                    </div>
                    <div class="col-12 my-2 col-lg-3 px-2 pr-lg-3">
                        <div class="content-select">
                            <select name="type"
                                id="type"
                                ref={refType}
                                onChange={() => updateRoute(refType) + updateTypes(refType)}>
                                <option value="all">Tipo de propiedad</option>
                                    {listFiltersByOperation.data ? 
                                        listFiltersByOperation.data.objects.property_types.map((item,index) => (
                                            <option value={item.type}>{item.type}</option>
                                            )
                                        )
                                        :''
                                    }
                            </select>
                            <i></i>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="row no-gutters d-flex align-items-stretch">
                            <div class="col-12 my-2 col-lg-9 px-2 pr-lg-3">
                                <div class={"content-select " + (errorInput ? "error-border" : '')}>
                                    {locationsAdv 
                                    ?   <SearcherPro updateRoute={updateRoute} />
                                    :   <Searcher lang={locations} updateRoute={updateRoute} />}
                                </div>  
                            </div>
                            <div class="col-12 my-2 px-2 px-lg-0 col-lg-3">
                                <button class="btn btn-white">BUSCAR</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div> 
    ):''
}

export default connect(state => ({
    totalListFilters: state.properties.totalListFilters,
    listLocationLite: state.properties.listLocationLite,
    listFiltersByOperation: state.properties.listFiltersByOperation,
    searchHome:state.properties.searchHome
}),null)(Search);
